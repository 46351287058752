// sm 640
@mixin screen-40 {
    @media (max-width: 40em) {
        @content;
    }
}

// md 768
@mixin screen-48 {
    @media (max-width: 48em) {
        @content;
    }
}

// lg 1024
@mixin screen-64 {
    @media (max-width: 64em) {
        @content;
    }
}

// xl 1280
@mixin screen-80 {
    @media (max-width: 80em) {
        @content;
    }
}

// 2xl 1536
@mixin screen-96 {
    @media (max-width: 96em) {
        @content;
    }
}

// 970
@mixin screen-60 {
    @media (max-width: 60.6em) {
        @content;
    }
}
