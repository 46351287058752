$carousel-speed: 20s;

.carousel {
    display: flex;
    flex-direction: column;
    gap: 0.8em;
    overflow: hidden;
    padding: 1em 0;
    position: relative;

    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        // outline: 2px solid red;
        background: rgb(22, 22, 22);
        background: linear-gradient(
            180deg,
            rgba(22, 22, 22, 1) 0%,
            rgba(38, 38, 38, 0.1540265764508929) 100%
        );
    }
    .left {
        display: flex;
        gap: 1em;
        // overflow: hidden;
        white-space: nowrap;

        p {
            padding: 0.5em 1em;
            font-size: 1.5rem;
            color: white;
            font-weight: 600;
            border: 0.08em dashed #00e3fc;
            border-radius: 0.5em;
            flex-shrink: 0; // Ensure items don’t shrink
        }
    }
    .right_container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .right {
            display: flex;
            gap: 1em;
            // overflow: hidden;
            white-space: nowrap;
            // outline: 2px solid red;
            p {
                padding: 0.5em 1em;
                font-size: 1.5rem;
                color: white;
                font-weight: 600;
                border: 0.08em dashed #00e3fc;
                border-radius: 0.5em;
                flex-shrink: 0;
            }
        }
    }
    > :nth-child(1) {
        width: calc(81.5em * 3 + 2em);
        animation: child1 $carousel-speed linear infinite;
    }
    > :nth-child(2) {
        div {
            width: calc(87.4em * 3 + 2em);
            animation: child2 $carousel-speed linear infinite;
        }
    }
    > :nth-child(3) {
        // outline: 2px solid red;
        width: calc(70.9em * 3 + 2em);
        animation: child3 $carousel-speed linear infinite;
    }
    > :nth-child(4) {
        div {
            // outline: 2px solid red;
            width: calc(81.9em * 3 + 2em);
            animation: child4 $carousel-speed linear infinite;
        }
    }
}

@keyframes child1 {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-33.5%);
    }
}

@keyframes child2 {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(33.5%);
    }
}

@keyframes child3 {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-33.5%);
    }
}

@keyframes child4 {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(33.5%);
    }
}
