.hero {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    gap: clamp(1em, 2.5vi, 2.5em);
    .heading {
        h1 {
            font-size: clamp(2.5rem, 4vi, 4rem);
            width: min(85%, 14.5em);
            margin: auto;
            color: white;
            line-height: 1.3;
            font-weight: 400;
            // outline: 2px solid red;
            span {
                font-family: "Playfair Display", serif;
                color: #00e3fc;
                font-style: italic;
                font-weight: 500;
            }
        }
    }
    .sub_heading {
        // outline: 2px solid red;
        width: min(85%, 26.29em);
        margin-inline: auto;
        color: #a3a3a3;
        span {
            color: white;
        }
    }
    .fee {
        p {
            // outline: 2px solid red;
            color: #a3a3a3;
            font-size: 0.8rem;
            margin-inline: 1em;
        }
    }
}
