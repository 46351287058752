@use "/src/styles/media_queries" as ab;
.faq {
    width: min(85%, 65em);
    margin-inline: auto;
    display: grid;
    margin-block: 8em;
    gap: 2em;
    h3 {
        justify-self: center;
        text-align: center;
        // outline: 2px solid red;
        background-color: #1f1f1f;
        color: white;
        width: 5em;
        padding-block: 0.3em;
        border-radius: 1em;
        font-weight: 400;
    }
    > div {
        display: flex;
        flex-direction: column;
        align-items: center;

        .heading {
            // outline: 2px solid red;
            width: clamp(17em, 38vi, 38em);
            p {
                text-align: center;
                font-size: clamp(1.5rem, 3.5vi, 3.5rem);
                // font-weight: 500;
                color: white;
            }
        }
        .content {
            display: flex;
            gap: clamp(1em, 2vi, 2em);
            margin-block: 3em;
            @include ab.screen-60 {
                flex-direction: column;
            }
            .accordion {
                display: flex;
                flex-direction: column;
                gap: 1em;
                width: 60%;
                @include ab.screen-60 {
                    width: 100%;
                }
                .Accordion {
                    // width: 60%;
                    background-color: #161616;
                    border: 0.1em solid #404040;
                    border-radius: 1em;
                    .AccordionSummary {
                        color: black;
                        padding-inline: 1.5em;
                        padding-block: 0.8em;

                        .typo {
                            display: flex;

                            p {
                                text-align: left;
                                color: white;
                            }
                        }
                    }
                    .AccordionDetails {
                        padding-inline: 1.5em;
                        padding-bottom: 0.8em;
                        p {
                            color: white;
                        }
                    }
                }
            }
            .contact {
                width: 40%;
                background-color: white;
                display: flex;
                flex-direction: column;
                gap: 2em;
                justify-content: end;
                align-items: center;
                border-radius: 1em;
                padding: 1.2em;

                height: 80%;
                @include ab.screen-60 {
                    width: 100%;
                }
                img {
                    width: clamp(5em, 6vi, 6em);
                    aspect-ratio: 1;
                }
                .link {
                    display: grid;
                    gap: 1em;
                    text-align: center;
                    width: 100%;
                    @include ab.screen-60 {
                        justify-content: center;
                    }

                    p {
                        font-size: clamp(1.2rem, 1.4vi, 1.4rem);
                    }
                    a {
                        width: 100%;
                        border-radius: 1em;
                        background-color: #262626;
                        font-size: clamp(1rem, 1.2vi, 1.2rem);
                        color: white;
                        padding-block: 1em;
                        // padding-inline: 3em;
                        text-decoration: none;
                        @include ab.screen-60 {
                            width: 16.5em;
                        }
                    }
                }
            }
        }
    }
}
