@use "/src/styles/media_queries" as ab;
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-block: 0.5rem;
    padding-inline: clamp(1.5em, 2.8vi, 2.8em);
    // background-color: rgba(255, 255, 255, 0.65);
    border-radius: 4em;
    // margin-top: clamp(1em, 2vi, 2em);
    top: 1.5em;
    position: sticky;
    z-index: 5;
    transition: transform 0.3s ease-in-out;
    overflow: hidden;
    background-color: rgb(255, 255, 255);

    &.hide {
        transform: translateY(-142%);
        transition: transform 0.3s ease-in-out;
    }

    .navbar_logo {
        font-size: 1.5rem;
        display: flex;
        align-items: center;
        img {
            width: 6em;
        }
    }

    .hamburger {
        display: none;
        flex-direction: column;
        cursor: pointer;
        z-index: 10;

        .bar {
            width: 2em;
            height: 0.2em;
            background-color: #161616;
            margin: 4px 0;
            transition: 0.4s;
            border-radius: 0.2em;
        }

        .open:nth-child(1) {
            transform: rotate(45deg) translate(10px, 5px);
        }

        .open:nth-child(2) {
            opacity: 0;
        }

        .open:nth-child(3) {
            transform: rotate(-45deg) translate(10px, -5px);
        }
    }

    .nav_links {
        list-style: none;
        display: flex;
        gap: clamp(1.5em, 2.5vi, 2.5em);
        margin: 0;
        align-items: center;

        li a {
            color: black;
            text-decoration: none;
            font-size: 1rem;
            margin: 0em;
            font-weight: 600;
            //   cursor: pointer;
            &:hover {
                color: #2c2e2c;
            }
        }
        > :nth-child(4) {
            border-radius: 0.5em;
            padding-inline: 0.8em;
            padding-block: 0.5em;
            background-color: #00e3fc;
        }
        @include ab.screen-48 {
            position: fixed;
            top: 0;
            right: -115vw;
            height: 100vh;
            width: 100vw;
            background-color: white;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            transition: right 0.3s ease-in-out;
            z-index: 9;
            padding: 0;

            &.open {
                transition: right 0.3s ease-in-out;
                right: 0;
                backdrop-filter: blur(none);
            }

            li {
                margin: 0;
            }
        }
    }

    @include ab.screen-48 {
        .hamburger {
            display: flex;
        }
    }
}
